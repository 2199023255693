import React from 'react';

import NewsletterSignUp from '../NewsletterSignUp/NewsletterSignUp.js';
import RenderHtmlContent from '../RenderHtmlContent/RenderHtmlContent';
import { isUserLoggedIn } from '../../services/token-service/token.service';
import { SignInController } from '../../controllers/sign-in-controller/sign-in.controller';
import { DashboardController } from '../../controllers/dashboard-controller/DashboardController';

import { ReactComponent as TwitterIcon } from '../../images/twitter_icon.svg';
import { ReactComponent as InstagramIcon } from '../../images/instagram_icon.svg';
import { links } from '../../links';

import ApplicationLogo from '../ApplicationLogo/ApplicationLogo';

import './Footer.scss';

function Footer() {
  const footerContent = DashboardController.getWhitelabelConfig();

  return (
    <div className="Footer">
      <div className="DesktopLogoContainer">
        <ApplicationLogo notLink />
      </div>

      <NewsletterSignUp />

      <div className="FooterSignInContainer">
        {!isUserLoggedIn() && (
          <div className="Heading">
            Have an account? <span onClick={() => SignInController.showSignInDialog()}>Sign in</span>
          </div>
        )}
      </div>

      <div className="SocialIconsContainer">
        <a className="Icons TwitterLinkIcon" target="_blank" rel="noreferrer" href={links.twitter}>
          <TwitterIcon />
        </a>
        <a className="Icons InstagramLinkIcon" target="_blank" rel="noreferrer" href={links.instagram}>
          <InstagramIcon />
        </a>
      </div>

      <RenderHtmlContent htmlContent={footerContent} dataLabel="footer_links" />
      <RenderHtmlContent htmlContent={footerContent} dataLabel="footer_text" />
    </div>
  );
}

export default Footer;